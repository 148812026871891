<template>
  <v-container>
    <div class="confirm-container mx-auto">
      <v-row no-gutters class="text-center">
        <v-col cols="12">
          <v-img
            src="/img_layout/registration-confirmed.png"
            width="80"
            class="mx-auto"
          />
          <h1>{{ $t("register.completed.title") }}</h1>
          <div class="msg-body my-3">
            {{
              socialLogin
                ? $t("register.completed.textSocialLogin")
                : $t("register.completed.text", [mail])
            }}
          </div>
          <p v-if="!socialLogin" class="confirm mt-5 mb-3 font-weight-bold">
            {{ $t("register.completed.socialConfirm") }}
          </p>

          <div v-if="!agreement && !socialLogin">
            <p class="warning-text font-weight-bold">
              {{ $t("register.completed.warning") }}
            </p>
          </div>
          <p v-if="agreement">
            <strong>{{ $t("register.completed.reminder") }}</strong>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" class="btn-container">
        <v-btn
          large
          depressed
          class="primary white--text"
          min-width="200"
          @click="goToHome"
          >{{ $t("register.completed.goToHome") }}</v-btn
        >
      </v-row>
    </div>
  </v-container>
</template>
<style lang="scss">
.confirm-container {
  width: 60%;
  padding: 35px 0 0x;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
  }
  h1 {
    font-size: 50px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 25px;
    }
  }
  .msg-body,
  .confirm,
  .warning-text {
    font-size: 20px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 18px;
    }
  }
  .warning-text {
    color: #f80100;
  }
  .btn-container {
    margin-top: 90px;
  }
}
</style>
<script>
import categoryMixins from "~/mixins/category";
import login from "~/mixins/login";

export default {
  name: "RegistrationCompleted",

  data() {
    return {
      mail: "",
      socialLogin: null,
      agreement: null
    };
  },
  mixins: [categoryMixins, login],
  methods: {
    async goToHome() {
      // if (await this.needAddress()) {
      //   if (await this.needTimeslot()) {
      if (this.isCordova) {
        this.$router.push({
          name: "HomeApp",
          path: "/app"
        });
      } else {
        this.$router.push({
          name: "HomeSite",
          path: "/"
        });
      }

      //   }
      // }
    }
  },
  created() {
    this.mail = this.$route.params.mail;
    this.socialLogin = this.$route.params.socialLogin;
    this.agreement = this.$route.params.agreement;
  }
};
</script>
